<template>
  <v-container fluid>
    <h3 class="page-title d-flex justify-space-between align-center">
      一番賞庫存(格主)

      <v-btn color="primary" @click="openDialog('create')">
        <v-icon>mdi-plus</v-icon>
        新增庫存
      </v-btn>
    </h3>
    <v-row>
      <v-col>
        <h4>篩選條件</h4>
      </v-col>
      <!-- <v-col cols="auto" align-self="end">
        <v-btn @click="exportExcel" color="secondary" outlined>匯出</v-btn>
      </v-col> -->
    </v-row>
    <v-row align="center">
      <v-col cols="6" sm="4" md="2">
        <v-select
          v-model="filterQuery.branch"
          :items="branchOptions"
          label="店點"
          outlined
          dense
          hide-details
          clearable
        >
        </v-select>
      </v-col>
      <v-col cols="6" sm="4" md="2">
        <v-select
          v-model="filterQuery.status"
          :items="statusOptions"
          label="狀態"
          outlined
          dense
          hide-details
          clearable
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          label="關鍵字"
          placeholder="關鍵字(品項、格主名稱、格主電話)"
          v-model.trim="filterQuery.keyword"
          outlined
          dense
          hide-details
          clearable
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="filterData"
          :mobile-breakpoint="0"
          item-key="index"
          hide-default-footer
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [10, 50, -1],
          }"
        >
          <template v-slot:item.user="{ item }">
            {{ item.user.name }}({{ item.user.phone }})
          </template>
          <template v-slot:item.branch="{ item }">
            {{ item.branch }}店
          </template>
          <template v-slot:item.updatedAt="{ item }">
            {{ dayjs(item.updatedAt).format("YYYY/MM/DD HH:mm") }}
          </template>
          <template v-slot:item.status="{ item }">
            <v-select
              v-model="item.status"
              :items="['待收貨', '已入庫', '已出貨']"
              @change="update(item, 'status')"
              class="typeWidth"
              hide-details
              outlined
              dense
            ></v-select>
          </template>
          <template v-slot:item.action="{ item }">
            <div class="my-1">
              <v-btn
                class="my-1 mx-2"
                color="primary"
                @click="openDialog('edit', item)"
              >
                編輯
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogCreateStock
      v-if="dialog.open"
      v-model="dialog.open"
      :dialog="dialog"
      :stock="payload"
      @load="load"
      :users="users"
    />
  </v-container>
</template>

<script>
import util from "@/mixins/util";
import dayjs from "dayjs";
import _ from "lodash";
import {
  translateDataKey,
  exportToExcel,
  reorderObjectKey,
} from "@/utils/exportExcel.js";
import DialogCreateStock from "@/components/dashboard/ichiban/DialogCreateStock.vue";
import { sortDateDesc } from "@/utils/utils.js";

export default {
  name: "OwnerStock",
  mixins: [util],
  components: { DialogCreateStock },
  data: () => ({
    dayjs,
    stocks: [],
    users: null,
    payload: {
      name: "",
      amount: 0,
      userID: "",
      branch: "",
      status: "",
      comment: "",
    },
    showSum: false,
    dialog: { type: "create", open: false },
    menu: false,
    filterQuery: {
      date: [],
      keyword: "",
      branch: "",
      status: null,
    },
    branchOptions: [],
    statusOptions: [],
    headers: [
      {
        text: "品項",
        value: "name",
        align: "left",
        sortable: false,
      },
      {
        text: "數量",
        value: "amount",
        align: "right",
        sortable: false,
      },
      {
        text: "店點",
        value: "branch",
        align: "center",
        sortable: false,
      },
      {
        text: "格主",
        value: "user",
        align: "left",
        sortable: false,
      },
      {
        text: "狀態",
        value: "status",
        align: "center",
        sortable: false,
      },
      {
        text: "備註",
        value: "comment",
        align: "left",
        sortable: false,
        width: "150px",
      },
      {
        text: "更新時間",
        value: "updatedAt",
        align: "left",
        sortable: true,
        sort: (a, b) => {
          if (dayjs(a).isAfter(dayjs(b))) {
            return -1;
          }
          if (dayjs(a).isBefore(dayjs(b))) {
            return 1;
          }
          return 0;
        },
      },
      {
        text: "操作",
        value: "action",
        align: "center",
        sortable: false,
      },
    ],
  }),
  computed: {
    filterData() {
      const { keyword, branch, status } = this.filterQuery;
      return this.stocks
        .filter((item) => !branch || item.branch === branch)
        .filter((item) => !status || item.status === status)
        .filter((item) => {
          const hasKeyword = [item.name, item.user.name, item.user.phone].some(
            (value) => {
              return (
                value && value.toUpperCase().includes(keyword.toUpperCase())
              );
            }
          );
          return !keyword || hasKeyword;
        });
    },
  },
  async created() {
    await this.getUsers();
    await this.load();
  },
  methods: {
    async load() {
      this.$vloading.show();
      try {
        const { data } = await this.axios.get(`/ichibanStock`);

        this.stocks = data;
        this.branchOptions = [
          { value: null, text: "全部" },
          ..._.uniqBy(data, "branch").map((item) => {
            return {
              value: item.branch,
              text: item.branch + "店",
            };
          }),
        ];
        this.statusOptions = [
          { value: null, text: "全部" },
          ..._.uniqBy(data, "status").map((item) => {
            return {
              value: item.status,
              text: item.status,
            };
          }),
        ];
      } catch (error) {}
      this.$vloading.hide();
    },
    async update(stock, key) {
      try {
        await this.axios.put(`/ichibanStock/${stock._id}`, {
          [key]: stock[key],
        });
        this.load();
        this.$toast.success("更新完成");
      } catch (e) {
        console.error(e);
        this.$toast.error("更新失敗");
      }
    },
    async getUsers() {
      const { data } = await this.axios.get("/users", {
        params: { store: "@695yimoc", isVerified: true },
      });
      this.users = data.sort(sortDateDesc("createdAt"));
    },
    clear() {
      this.dialog.open = false;
    },
    openDialog(type, payload) {
      this.payload = payload;
      this.dialog.type = type;
      this.dialog.open = true;
    },
    exportExcel() {
      const omitKeys = ["_id", "updatedAt", "createdAt"];
      const keyTranslation = {
        date: "日期",
        branch: "店點",
        type: "收支",
        name: "內容",
        category: "種類",
        source: "來源",
        amount: "金額",
      };
      const desiredKeyOrder = Object.keys(keyTranslation);
      const data = translateDataKey(
        this.filterData.map((item) => reorderObjectKey(item, desiredKeyOrder)),
        keyTranslation,
        omitKeys
      );
      exportToExcel(data, "收支");
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.typeWidth {
  width: 130px;
}
</style>
