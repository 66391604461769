<template>
  <v-dialog v-model="open" persistent max-width="600">
    <v-card>
      <v-card-title class="mb-3">
        {{ dialog.type === "create" ? "新增一番賞庫存" : "編輯一番賞庫存" }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="payload.name"
              label="品項"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="12" sm="6">
            <v-text-field
              type="number"
              v-model.number="payload.amount"
              label="數量"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col> -->
          <v-col cols="12">
            <v-select
              v-model="payload.status"
              :items="['待收貨', '已入庫', '已出貨']"
              label="狀態"
              outlined
              dense
              hide-details
              clearable
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="payload.branch"
              :items="branches"
              label="店點"
              outlined
              dense
              hide-details
              clearable
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-model="payload.userID"
              :items="userOptions"
              label="格主"
              outlined
              dense
              hide-details
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="payload.comment"
              label="備註"
              outlined
              dense
              hide-details
              clearable
              no-resize
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn text color="error" @click="open = false" class="bt-text-large">
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="dialog.type === 'create'"
          text
          color="primary"
          @click="create"
          class="bt-text-large"
        >
          建立
        </v-btn>
        <v-btn
          v-else
          text
          color="primary"
          @click="update"
          class="bt-text-large"
        >
          儲存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogCreatePayment",
  props: ["value", "dialog", "stock", "users"],
  data: () => ({
    amount: null,
    payload: {
      name: "",
      amount: 1,
      userID: "",
      branch: "",
      status: "",
      comment: "",
    },
    branches: [
      { text: "文賢店", value: "文賢" },
      { text: "奇美店", value: "奇美" },
      { text: "崇善店", value: "崇善" },
    ],
  }),
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    userOptions() {
      if (!this.users) return [];
      return this.users.map((user) => {
        return {
          value: user.userID,
          text: `${user.name}(${user.phone})`,
        };
      });
    },
  },
  created() {
    if (this.stock) this.payload = _.cloneDeep(this.stock);
  },
  methods: {
    async create() {
      const payload = this.payload;
      if (!payload.comment) {
        delete payload.comment;
      }
      const hasMissField = Object.values(payload).some((value) => !value);

      if (hasMissField) {
        this.$toast.error("請填寫所有欄位(備註除外)");
        return;
      }

      try {
        this.$vloading.show();
        await this.axios.post(`/ichibanStock`, {
          ...this.payload,
        });
        this.$emit("load");
        this.open = false;
      } catch (error) {
        this.$toast.error(error.response?.data?.message || "建立失敗");
      }
      this.$vloading.hide();
    },
    async update() {
      this.$vloading.show();

      try {
        await this.axios.put(`/ichibanStock/${this.stock._id}`, {
          ...this.payload,
        });
        this.$emit("load");
        this.open = false;
      } catch (error) {
        this.$toast.error(error.response?.data?.message || "編輯失敗");
      }
      this.$vloading.hide();
    },
  },
};
</script>
