import dayjs from "dayjs";
import * as XLSX from "xlsx";
import _ from "lodash";

function translateDataKey(rawData, keyTranslation, omitKeys) {
  if (!rawData) return [];

  return rawData
    .map((object) => _.omit(object, omitKeys))
    .map((object) => {
      return Object.fromEntries(
        Object.entries(object).map(([key, value]) => [
          keyTranslation[key] || key,
          value,
        ])
      );
    });
}

function reorderObjectKey(object, desiredOrder) {
  const sortedKeys = _.intersection(desiredOrder, Object.keys(object));
  return _.fromPairs(sortedKeys.map((key) => [key, object[key]]));
}

function exportToExcel(data, fileName) {
  const workSheet = XLSX.utils.json_to_sheet(data);
  const workBook = XLSX.utils.book_new();
  const today = dayjs().format("YYYY-MM-DD_HH-mm");
  XLSX.utils.book_append_sheet(workBook, workSheet, "sheet 1");
  XLSX.writeFile(workBook, `${fileName}_${today}.xlsx`);
}

function exportToExcelSheets(sheets, fileName) {
  const workBook = XLSX.utils.book_new();
  const today = dayjs().format("YYYY-MM-DD_HH-mm");

  sheets.forEach(({ data, sheetName }) => {
    const workSheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);
  });
  XLSX.writeFile(workBook, `${fileName}_${today}.xlsx`);
}

export {
  translateDataKey,
  reorderObjectKey,
  exportToExcel,
  exportToExcelSheets,
};
